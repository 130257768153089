import { format } from 'date-fns';

import { Profile, SignupPayload } from '../types';

const getSignupPayload = (
  profile: Profile,
  paymentMethodId: string,
  isEligibleForFreeTrial: boolean
): SignupPayload => {
  const birthdate = format(new Date(profile.birthdate), 'yyyy-LL-dd');

  return {
    user: {
      date_of_birth: birthdate,
      firstname: profile.firstName,
      lastname: profile.lastName,
      email: profile.email,
      address_line_one: profile.addressLine1,
      address_line_two: profile.addressLine2 ? profile.addressLine2 : undefined, // Omni throws error on empty string
      city: profile.city,
      state: profile.state,
      country: 'US',
      zipcode: profile.postalCode,
      phone_type:
        profile.phoneType && (profile.phoneHome || profile.phoneMobile)
          ? profile.phoneType.toLocaleLowerCase()
          : undefined,
      phone_number: profile.phoneHome || profile.phoneMobile || undefined,
    },
    payment_method: {
      id: paymentMethodId,
    },
    order: {
      productId: process.env.HDC_PRODUCT_ID,
      inventoryId: isEligibleForFreeTrial
        ? process.env.HDC_FREE_TRIAL_INVENTORY_ID
        : process.env.HDC_2ND_GEAR_INVENTORY_ID,
      quantity: 1,
    },
  };
};

export { getSignupPayload };
