import React from 'react';
import { Link } from 'gatsby';

import { Profile } from './types';
import './styles.scss';

type MembershipWidgetAddressProps = {
  profile?: Profile;
};

const MembershipWidgetAddress: React.FC<MembershipWidgetAddressProps> = ({ profile }) => {
  if (!profile) return null;

  return (
    <div className="widget-address">
      <address>
        <strong>{`${profile.firstName} ${profile.lastName}`}</strong>
        <span>{profile.email}</span>
        <span>
          {profile.addressLine1} <br />
          {profile.addressLine2 && (
            <>
              {profile.addressLine2}
              <br />
            </>
          )}
          {`${profile.city}, ${profile.state}`} {profile.postalCode}
        </span>
      </address>
      <div>
        <Link to="/checkout/information">Edit</Link>
      </div>
    </div>
  );
};

export { MembershipWidgetAddress };
