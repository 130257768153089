import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import { useLocalStorage } from 'react-use';
import { useTreatments } from '@splitsoftware/splitio-react';
import { Alert } from '@hagerty/react-components';
import { useTranslation } from 'react-i18next';

import './payment.scss';

import { MembershipWidget, MembershipWidgetAddress, StripeSignupForm } from 'src/features/checkout-optimization';
import { getSignupPayload } from 'src/features/checkout-optimization//utils/get-signup-payload';
import { isAgent } from 'src/api/client';
import { isReady } from 'src/components/SplitContext/isReady';
import { usePurchaseFlowOptimization } from 'src/hooks/usePurchaseFlowOptimization';
import { useFreeTrial } from 'src/hooks/useFreeTrial';
import { SimpleFooter } from 'src/features/footer';
import { Translate } from 'src/components/Translate';
import { Split, Treatment } from 'src/constants/Split';
import { formatTestExperience } from 'src/helpers/formatTestExperience';
import { useTracking } from 'src/components/TrackingContext/useTracking';
import usePreventMemberCheckout from 'src/hooks/usePreventMemberCheckout';
import scrollToTop from 'src/helpers/scrollToTop';
import isBrowser from 'src/helpers/isBrowser';

function PaymentInformationPage({ location }) {
  const splitIsReady = isReady();
  const { hasNewPurchaseFlow } = usePurchaseFlowOptimization();
  const { isEligibleForFreeTrial } = useFreeTrial();
  const treatments = useTreatments([Split.FeatureCustomerPaymentDisclaimer]);
  const hasCustomerPaymentDisclaimer = treatments[Split.FeatureCustomerPaymentDisclaimer].treatment === Treatment.On;
  const [level] = useLocalStorage('SelectedLevel', isEligibleForFreeTrial && !isAgent ? 4 : 2);
  const [experiments, setExperiments] = useState([]);
  const { t } = useTranslation();
  const [formIsSubmitting, setFormIsSubmitting] = useState<boolean>(false);
  const [displayAlert, setDisplayAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>(t('generic.error.message'));

  const profile = location.state?.profile; // Member Information form values from /checkout/information
  const redirect = location.state?.redirect;

  usePreventMemberCheckout();

  useEffect(() => {
    if (splitIsReady) {
      const freeTrialExperiment = formatTestExperience(Split.FeatureFreeTrial, isEligibleForFreeTrial);
      setExperiments([freeTrialExperiment]);
    }
  }, [splitIsReady]);

  useTracking('checkout_payment_information', experiments);

  useEffect(() => {
    // Redirect if user has not filled out Member Information form
    if (!profile) {
      navigate('/checkout/information');
    }
  }, [profile]);

  useEffect(() => {
    // Redirect user without feature_purchase_flow_optimization flag
    if (splitIsReady && !hasNewPurchaseFlow) {
      navigate('/checkout/information');
    }
  }, [splitIsReady, hasNewPurchaseFlow]);

  const handleAlert = (message: string) => {
    setAlertMessage(message);
    setDisplayAlert(true);
    scrollToTop();
    setFormIsSubmitting(false);
  };

  const handleSignup = async (paymentMethodId: string) => {
    setFormIsSubmitting(true);
    const payload = getSignupPayload(profile, paymentMethodId, isEligibleForFreeTrial);

    try {
      const response = await fetch(`${process.env.BFF_URL}/signup`, {
        method: 'POST',
        body: JSON.stringify(payload),
      });
      const data = await response.json();
      if (response.status === 200) {
        if (isEligibleForFreeTrial && isBrowser && window.localStorage) {
          localStorage.setItem('hadFreeTrialProduct', 'true');
        }
        navigate(redirect, { state: { profile } });
      } else {
        handleAlert(data.message);
      }
    } catch (error) {
      console.error(error);
      if (error.errors.message) {
        handleAlert(error.errors.message);
      }
    }
  };

  return (
    <div className="payment-information color-background-light-gray">
      <div className="container container_center">
        {displayAlert && <Alert alertType="danger" messageText={alertMessage} />}
        <div className="payment-information__container">
          <div>
            <MembershipWidget hasFreeTrial={isEligibleForFreeTrial}>
              <MembershipWidgetAddress profile={profile} />
            </MembershipWidget>
          </div>
          <div className="payment-information__form-container">
            <h1 className="text-display_2 no-padding pb-4">Payment information</h1>
            <Translate
              className="color-dark-2 payment-information__description"
              resourceKey={
                isEligibleForFreeTrial
                  ? 'checkout.paymentPage.freeTrialCard.text'
                  : 'checkout.paymentPage.existingCard.text'
              }
              useHtml
              as="p"
            />
            <StripeSignupForm
              hasPaymentDisclaimer={hasCustomerPaymentDisclaimer}
              productLevel={level}
              profile={profile}
              onCreatePaymentMethodSuccess={handleSignup}
              setAlert={handleAlert}
              formIsSubmitting={formIsSubmitting}
            />
          </div>
        </div>
      </div>
      <div className="inset-l" />
      <SimpleFooter />
    </div>
  );
}

export default PaymentInformationPage;
