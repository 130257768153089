import React, { useEffect, useState } from 'react';
import {
  Elements,
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { useForm } from 'react-hook-form';
import { Button } from '@hagerty/react-components';
import { loadStripe, StripeElementChangeEvent } from '@stripe/stripe-js';

import './styles.scss';

import { Profile } from './types';
import { CardBrand } from 'src/components/atom';
import { Switch } from 'src/components/Switch';
import { StripeFieldset } from 'src/components/stripe-fieldset';
import BillingAddressFields from 'src/components/BillingAddressFields';
import LoadingContainer from 'src/components/LoadingContainer';
import { PaymentDisclaimer } from 'src/components/molecule';

interface StripeOnChangeEvent extends StripeElementChangeEvent {
  brand?: string;
}

const stripePromise = loadStripe(process.env.STRIPE_PUBLISHABLE_KEY);

type StripeSignupFormProps = {
  hasPaymentDisclaimer?: boolean;
  productLevel?: number;
  profile: Profile;
  onCreatePaymentMethodSuccess?: (paymentMethodId: string) => void;
  setAlert?: (errorMessage: string) => void;
  formIsSubmitting?: boolean;
};

const CreditCardForm: React.FC<StripeSignupFormProps> = ({
  hasPaymentDisclaimer = false,
  productLevel,
  profile,
  onCreatePaymentMethodSuccess,
  setAlert,
  formIsSubmitting = false,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isStripeFieldFocused, setIsStripeFieldFocused] = useState(false);
  const [cardBrand, setCardBrand] = useState<string>();
  const [hasSameMailingAddress, setHasSameMailingAddress] = useState(true);
  const [billingDetailsFromProfile, setBillingDetailsFromProfile] = useState({});
  const {
    errors,
    clearErrors,
    setError,
    setValue,
    register,
    trigger,
    control,
    handleSubmit: reactHookFormHandleSubmit,
    formState: { isValid },
  } = useForm({ mode: 'onChange' });

  const defaultPlaceholder = {
    cardNumber: '1234 1234 1234 1234',
    cardExpiry: 'MM / YY',
    cardCvc: '',
  };

  const stripeStyle = {
    invalid: {
      color: '#090A0D',
      iconColor: '#090A0D',
    },
    base: {
      '::placeholder': {
        color: !isStripeFieldFocused ? '#090A0D' : '#8E949A',
      },
    },
  };

  useEffect(() => {
    register({ name: 'cardNumber' }, { required: true });
    register({ name: 'cardExpiry' }, { required: true });
    register({ name: 'cardCvc' }, { required: true });
  }, []);

  useEffect(() => {
    if (formIsSubmitting !== isSubmitting) {
      setIsSubmitting(formIsSubmitting);
    }
  }, [formIsSubmitting]);

  useEffect(() => {
    if (profile) {
      setBillingDetailsFromProfile({
        address: {
          line1: profile.addressLine1,
          line2: profile.addressLine2,
          city: profile.city,
          state: profile.state,
          country: 'US',
          postal_code: profile.postalCode,
        },
        email: profile.email,
        name: `${profile.firstName} ${profile.lastName}`,
        phone: profile.phoneHome || profile.phoneMobile || '',
      });
    }
  }, [profile]);

  const handleFocus = () => {
    setIsStripeFieldFocused(true);
  };

  const handleBlur = () => {
    setIsStripeFieldFocused(false);
  };

  const handleChange = async ({ empty, brand, error, elementType }: StripeOnChangeEvent) => {
    brand && setCardBrand(brand);
    setValue(elementType, empty ? null : true);
    clearErrors(elementType);
    await trigger(elementType);
    if (error) {
      setError(elementType, { type: elementType, message: error.code || error.type });
    }
  };

  const handleSwitchClick = () => {
    setHasSameMailingAddress(!hasSameMailingAddress);
  };

  const onSubmit = (data) => {
    if (!elements || !stripe) {
      return;
    }
    setIsSubmitting(true);

    const stripeBillingDetails = hasSameMailingAddress
      ? billingDetailsFromProfile
      : {
          address: {
            line1: data.addressLine1,
            line2: data.addressLine2,
            city: data.city,
            state: data.state,
            country: 'US',
            postal_code: data.postalCode,
          },
          email: profile.email,
          name: `${profile.firstName} ${profile.lastName}`,
          phone: data.recipientPhone || '',
        };

    stripe
      .createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardNumberElement)!,
        billing_details: {
          ...stripeBillingDetails,
        },
      })
      .then((response) => {
        if (response.error) {
          setAlert && setAlert(response.error.message);
          setIsSubmitting(false);
          console.log(response.error);
        } else {
          const paymentMethodId = response?.paymentMethod?.id;
          paymentMethodId && onCreatePaymentMethodSuccess && onCreatePaymentMethodSuccess(paymentMethodId);
        }
      });
  };

  const addressLines = profile?.addressLine2
    ? `${profile?.addressLine1} ${profile?.addressLine2},`
    : `${profile?.addressLine1},`;
  const mailingAddressString = `Same mailing address: ${addressLines} ${profile?.city}, ${profile?.state} ${profile?.postalCode}`;

  return (
    <form className="stripe-signup-form" onSubmit={reactHookFormHandleSubmit(onSubmit)}>
      <div className="grid grid-wrap gutters">
        <div className="col xs-size_12-of-12 md-size_12-of-12" data-cy="checkoutCardNumber">
          <StripeFieldset errors={errors} fieldType="cardNumber">
            <div className="edit-cc__card-number">
              <CardNumberElement
                id="cardNumber"
                className="text-input"
                onFocus={handleFocus}
                onBlur={handleBlur}
                onChange={handleChange}
                options={{ style: stripeStyle, placeholder: defaultPlaceholder.cardNumber }}
              />
              <CardBrand brand={cardBrand} />
            </div>
          </StripeFieldset>
        </div>
        <div className="col xs-size_6-of-12 md-size_6-of-12" data-cy="checkoutExpDate">
          <StripeFieldset errors={errors} fieldType="cardExpiry">
            <CardExpiryElement
              id="cardExpiry"
              className="text-input"
              onFocus={handleFocus}
              onBlur={handleBlur}
              onChange={handleChange}
              options={{ style: stripeStyle, placeholder: defaultPlaceholder.cardExpiry }}
            />
          </StripeFieldset>
        </div>
        <div className="col xs-size_6-of-12 md-size_6-of-12" data-cy="checkoutCVC">
          <StripeFieldset errors={errors} fieldType="cardCvc">
            <CardCvcElement
              id="cardCvc"
              className="text-input"
              onFocus={handleFocus}
              onBlur={handleBlur}
              onChange={handleChange}
              options={{ style: stripeStyle, placeholder: defaultPlaceholder.cardCvc }}
            />
          </StripeFieldset>
        </div>
      </div>

      <legend className="text-heading_4 no-padding mb-4">Billing address</legend>
      <div className="display-flex align-items-center stripe-signup-form__switch-container">
        <Switch
          label="Billing address is the same as mailing address"
          checked={hasSameMailingAddress}
          onClick={handleSwitchClick}
          isDisabled={isSubmitting}
        />
        <p className="ml-4 font-size-small">{mailingAddressString}</p>
      </div>

      {!hasSameMailingAddress && (
        <BillingAddressFields
          address={billingDetailsFromProfile}
          register={register}
          control={control}
          errors={errors}
        />
      )}

      {hasPaymentDisclaimer && <PaymentDisclaimer type="terms" productLevel={productLevel} />}

      {isSubmitting && (
        <>
          <div className="inset-m" />
          <LoadingContainer height={40} />
        </>
      )}

      <Button
        buttonType="Primary"
        buttonSize="Large"
        testId="submit-payment-signup"
        type="submit"
        disabled={!stripe || isSubmitting || !isValid}
      >
        Purchase
      </Button>
    </form>
  );
};

const StripeSignupForm: React.FC<StripeSignupFormProps> = ({
  hasPaymentDisclaimer,
  productLevel,
  profile,
  onCreatePaymentMethodSuccess,
  setAlert,
  formIsSubmitting,
}) => {
  return (
    <Elements stripe={stripePromise}>
      <CreditCardForm
        hasPaymentDisclaimer={hasPaymentDisclaimer}
        productLevel={productLevel}
        profile={profile}
        onCreatePaymentMethodSuccess={onCreatePaymentMethodSuccess}
        setAlert={setAlert}
        formIsSubmitting={formIsSubmitting}
      />
    </Elements>
  );
};

export { StripeSignupForm };
