import React from 'react';
import cx from 'classnames';

type SwitchProps = {
  checked: boolean;
  isDisabled?: boolean;
  label: string;
  testId?: string;
  onClick?: () => void;
};

const Switch: React.FC<SwitchProps> = ({
  checked = false,
  isDisabled = false,
  label,
  testId = 'switch-btn',
  onClick,
}) => {
  const classNames = cx('switch', { switch__checked: checked });

  return (
    <>
      <label className="visibly-hidden" htmlFor={testId}>
        {label}
      </label>
      <button
        id={testId}
        className={classNames}
        role="switch"
        aria-checked={checked}
        aria-label="switch"
        onClick={onClick}
        type="button"
        disabled={isDisabled}
        data-testid={testId}
      ></button>
    </>
  );
};

export { Switch };
